import React, { useEffect } from 'react'

const Cursor = () => {

  const [text, setText] = React.useState('')
  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const [scale, setScale] = React.useState(0.2)

  useEffect(() => {

    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY })
    }

    window._handleMouseEnter = (t) => {
      if (t !== 'Link') {
        setText(t)
        setScale(1)
      } else {
        setText('')
        setScale(0.4)
      }
    }

    window._handleMouseLeave = () => {
      setText('')
      setScale(0.2)
    }

    document.addEventListener('mousemove', handleMouseMove)
    //document.addEventListener('mouseenter', handleMouseEnter)
    //document.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      //document.removeEventListener('mouseenter', handleMouseEnter)
      //document.removeEventListener('mouseleave', handleMouseLeave)
    }

  }, [])

  return (
    <div className={`c-cursor${text ? ' c-cursor--text' : ''}`} style={{transform: `translate3d(${position.x}px, ${position.y}px, 0px)`}}>
      <div className='cursor' style={{transform: `scale(${scale}, ${scale})`}}></div>
    </div>
  )
}

export default Cursor