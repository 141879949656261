import React from 'react'

export const Logo = ({color}) => (
  <svg width="99" height="34" viewBox="0 0 99 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.348 1.88L0 32.332V33.62H5.75L6.624 28.744H13.754L14.628 33.62H20.47V32.332L14.076 1.88H6.348ZM9.522 12.874L9.752 8.596H10.58L10.902 12.874L12.834 23.546H7.59L9.522 12.874Z" fill={color}/>
    <path d="M29.6043 28.744C28.2703 28.744 27.6263 27.962 27.6263 26.444V14.576H30.2943V10.206H27.6263V4.502H21.8303V10.206H19.6223V14.576H21.8303V27.962C21.8303 31.964 23.7163 33.988 27.5343 33.988C28.5003 33.988 29.4663 33.85 30.3403 33.62V28.652C30.1563 28.652 29.7883 28.744 29.6043 28.744Z" fill={color}/>
    <path d="M39.1882 33.988C44.8462 33.988 47.6522 29.986 47.6522 21.936C47.6522 13.886 44.8462 9.838 39.1882 9.838C33.6222 9.838 30.8162 13.886 30.8162 21.936C30.8162 29.986 33.6222 33.988 39.1882 33.988ZM39.1882 28.79C37.3942 28.79 36.7962 26.904 36.7962 21.936C36.7962 16.83 37.3942 15.036 39.1882 15.036C41.0282 15.036 41.6262 16.784 41.6262 21.936C41.6262 26.904 41.0282 28.79 39.1882 28.79Z" fill={color}/>
    <path d="M54.7244 33.62V0.5H48.9284V33.62H54.7244Z" fill={color}/>
    <path d="M62.6131 33.62V0.5H56.8171V33.62H62.6131Z" fill={color}/>
    <path d="M72.2718 33.988C77.9298 33.988 80.7358 29.986 80.7358 21.936C80.7358 13.886 77.9298 9.838 72.2718 9.838C66.7058 9.838 63.8998 13.886 63.8998 21.936C63.8998 29.986 66.7058 33.988 72.2718 33.988ZM72.2718 28.79C70.4778 28.79 69.8798 26.904 69.8798 21.936C69.8798 16.83 70.4778 15.036 72.2718 15.036C74.1118 15.036 74.7098 16.784 74.7098 21.936C74.7098 26.904 74.1118 28.79 72.2718 28.79Z" fill={color}/>
    <path d="M92.424 9.838C89.25 9.838 87.18 11.586 86.398 14.116H85.8L86.306 11.678V10.206H82.12V33.62H87.916V18.302C87.916 16.232 88.882 15.036 90.492 15.036C91.78 15.036 92.424 15.818 92.424 17.428V33.62H98.174V16.186C98.174 12.138 95.966 9.838 92.424 9.838Z" fill={color}/>
  </svg>
)

export const ApplyNow = () => (
  <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="55" cy="55" r="55" fill="#04C100"/>
    <path d="M45.5646 20.2361L41.2868 21.7013L41.3825 24.4881L39.0927 25.2723L38.8828 12.3835L41.7249 11.4116L49.4743 21.7197L47.1992 22.4965L45.5646 20.2361ZM41.2058 19.6176L44.3314 18.5463L41.0291 13.9813L41.2021 19.6176H41.2058Z" fill="black"/>
    <path d="M54.8159 17.4751L52.8721 17.5082L52.942 21.7492L50.6117 21.7861L50.4092 9.52314L54.6833 9.45319C57.2972 9.4127 59.1526 10.5466 59.2005 13.4108C59.2483 16.2565 57.4297 17.4346 54.8122 17.4751H54.8159ZM54.595 11.4338L52.7764 11.4633L52.8426 15.5276L54.6613 15.4981C56.005 15.476 56.8075 14.8281 56.7854 13.4476C56.7634 12.0523 55.9387 11.4117 54.595 11.4338Z" fill="black"/>
    <path d="M65.8164 19.0692L63.9499 18.5244L62.7608 22.5961L60.5225 21.9408L63.9646 10.1675L68.0694 11.3676C70.5802 12.1002 72.0159 13.7385 71.2134 16.4848C70.4145 19.2165 68.3271 19.8018 65.8201 19.0692H65.8164ZM67.3957 13.2341L65.647 12.7224L64.5058 16.6247L66.2508 17.1365C67.5393 17.512 68.5002 17.1328 68.8867 15.8075C69.2769 14.4674 68.6806 13.6133 67.392 13.2341H67.3957Z" fill="black"/>
    <path d="M76.5702 14.7578L78.5324 16.0169L73.0728 24.521L77.5347 27.3852L76.3714 29.2001L69.9473 25.0769L76.5702 14.7578Z" fill="black"/>
    <path d="M81.6502 28.2173L84.4849 19.9487L86.2962 21.8815L84.3671 27.3448L89.9445 25.7765L91.719 27.6724L83.2075 29.9549L79.931 33.0216L78.3369 31.3207L81.6502 28.2173Z" fill="black"/>
    <path d="M87.9385 48.9477L87.0292 46.2345L94.0755 38.9526L85.5456 41.8094L84.8203 39.6484L96.45 35.7534L97.3483 38.4335L90.2946 45.7522L98.8393 42.8917L99.5645 45.0528L87.9348 48.9477H87.9385Z" fill="black"/>
    <path d="M87.8574 55.6811C87.942 52.4119 90.5264 50.2877 94.3404 50.3871C98.158 50.4865 100.625 52.7433 100.54 56.0124C100.455 59.2815 97.8709 61.4057 94.0532 61.3063C90.2356 61.2069 87.769 58.9502 87.8537 55.6811H87.8574ZM89.9226 55.7363C89.8785 57.485 91.4689 58.7808 94.1195 58.8508C96.7701 58.9207 98.4268 57.7095 98.4746 55.9572C98.5188 54.2269 96.9284 52.9126 94.2778 52.8427C91.6272 52.7727 89.9705 54.0023 89.9226 55.7363Z" fill="black"/>
    <path d="M86.0276 66.4862L87.1468 64.0049L99.487 66.4715L98.5482 68.5478L89.2747 66.5929L96.9063 72.1887L95.7724 74.6995L86.5209 72.6931L94.1305 78.3368L93.1991 80.3984L83.1855 72.7778L84.3047 70.2965L93.6887 72.2256L86.0276 66.4788V66.4862Z" fill="black"/>
    <path d="M64.8075 89.2598L69.0706 87.7467L68.9454 84.9598L71.2279 84.1499L71.5776 97.0387L68.7466 98.0437L60.8867 87.8203L63.1508 87.0178L64.8075 89.2598ZM69.1737 89.8304L66.0591 90.9348L69.4093 95.4667L69.1737 89.8304Z" fill="black"/>
    <path d="M55.5598 92.1163L57.5036 92.0721L57.4042 87.8311L59.7346 87.7759L60.0217 100.035L55.7476 100.135C53.1337 100.197 51.2672 99.0743 51.201 96.2101C51.1347 93.3643 52.946 92.1752 55.5598 92.1126V92.1163ZM55.8249 98.1539L57.6435 98.1097L57.5478 94.0454L55.7292 94.0896C54.3854 94.1227 53.5902 94.778 53.6197 96.1549C53.6528 97.5501 54.4811 98.1833 55.8249 98.1539Z" fill="black"/>
    <path d="M44.5666 90.6403L46.4367 91.1667L47.5853 87.084L49.831 87.7135L46.514 99.5236L42.3982 98.3676C39.8801 97.6608 38.4259 96.041 39.199 93.2836C39.9684 90.5446 42.0484 89.9371 44.5666 90.6439V90.6403ZM43.0498 96.4938L44.8022 96.9871L45.9029 93.0737L44.1506 92.5804C42.8584 92.2159 41.9012 92.6098 41.5294 93.9388C41.1502 95.2826 41.7576 96.133 43.0498 96.4975V96.4938Z" fill="black"/>
    <path d="M33.8617 95.0652L31.8848 93.8245L37.2523 85.2614L32.761 82.4451L33.9059 80.6191L40.3742 84.6724L33.858 95.0615L33.8617 95.0652Z" fill="black"/>
    <path d="M28.6452 81.6645L25.8988 89.9662L24.0654 88.0518L25.9356 82.5701L20.3766 84.1973L18.5801 82.3198L27.0695 79.9453L30.3129 76.8418L31.9253 78.5279L28.6452 81.6682V81.6645Z" fill="black"/>
    <path d="M22.1287 60.9932L23.0675 63.699L16.0985 71.0509L24.5953 68.1057L25.3426 70.2594L13.7571 74.2758L12.8331 71.6031L19.8094 64.2108L11.2942 67.1633L10.5469 65.0096L22.1324 60.9932H22.1287Z" fill="black"/>
    <path d="M22.1392 54.2452C22.0877 57.5143 19.5254 59.6643 15.7077 59.6017C11.8901 59.5391 9.40139 57.3081 9.45293 54.039C9.50447 50.7699 12.0668 48.6199 15.8844 48.6825C19.7021 48.7451 22.1907 50.976 22.1392 54.2452ZM20.0739 54.212C20.1034 52.4633 18.4983 51.1822 15.8476 51.138C13.197 51.0938 11.5514 52.3234 11.5219 54.0721C11.4925 55.8024 13.0976 57.102 15.7482 57.1461C18.3989 57.1903 20.0445 55.9423 20.0739 54.212Z" fill="black"/>
    <path d="M23.8549 43.4368L22.7615 45.9291L10.3955 43.5951L11.3122 41.5077L20.6078 43.3631L12.9136 37.8483L14.0217 35.3228L23.2953 37.2261L15.6232 31.6635L16.5325 29.5908L26.627 37.1047L25.5336 39.597L16.1275 37.771L23.8512 43.4331L23.8549 43.4368Z" fill="black"/>
  </svg>
)


export const DownArrow = () => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 22L16 13.7469L14.8362 12.5463L8.82243 18.7503L8.82243 1.27987e-06L7.17757 1.13607e-06L7.17757 18.7503L1.16382 12.5463L-1.18584e-06 13.7469L8 22Z" fill="black"/>
  </svg>
)

export const SymbolLogo = ({color}) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.0006 47.3991C36.9288 47.3991 47.3996 36.9283 47.3996 24.0001C47.3996 11.0719 36.9154 0.601074 24.0006 0.601074C11.0857 0.601074 0.601562 11.0852 0.601562 24.0001C0.601562 36.9149 11.0723 47.3991 24.0006 47.3991ZM24.0006 47.3991C35.4463 47.3991 44.7418 38.1169 44.7418 26.6578C44.7418 15.1987 35.4597 5.9166 24.0006 5.9166C12.5415 5.9166 3.25933 15.2121 3.25933 26.6578C3.25933 38.1036 12.5415 47.3991 23.9872 47.3991H24.0006ZM24.0006 47.3991C34.0173 47.3991 42.1375 39.2789 42.1375 29.2622C42.1375 19.2455 34.0173 11.1253 24.0006 11.1253C13.9839 11.1253 5.86367 19.2455 5.86367 29.2622C5.86367 39.2789 13.9839 47.3991 24.0006 47.3991ZM24.0006 47.3991C32.4413 47.3991 39.2927 40.5477 39.2927 32.1069C39.2927 23.6662 32.4413 16.8148 24.0006 16.8148C15.5598 16.8148 8.70841 23.6662 8.70841 32.1069C8.70841 40.5477 15.5598 47.3991 24.0006 47.3991ZM24.0006 47.3991C31.0122 47.3991 36.7017 41.7096 36.7017 34.6979C36.7017 27.6862 31.0122 22.0101 24.0006 22.0101C16.9889 22.0101 11.3127 27.6862 11.3127 34.6979C11.3127 41.7096 17.0022 47.3991 24.0006 47.3991ZM24.0006 47.3991C29.6767 47.3991 34.2844 42.7914 34.2844 37.1153C34.2844 31.4391 29.6767 26.8448 24.0006 26.8448C18.3244 26.8448 13.7301 31.4391 13.7301 37.1153C13.7301 42.7914 18.3378 47.3991 24.0006 47.3991Z" stroke={color} strokeWidth="1.62048"/>
    <path className='a' d="M27.2045 43.219H29.0208L24.934 31.1055H22.7704L18.6836 43.219H20.4866L21.515 40.0671H26.1761L27.2045 43.219ZM23.8522 32.9218L25.6819 38.5579H21.9958L23.8389 32.9218H23.8522Z" fill={color}/>
  </svg>
)

export const Play = ({color}) => (
  <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 5.02044L0 10.0409L4.38901e-07 0L6 5.02044Z" fill={color}/>
  </svg>
)

export const Close = ({color}) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill={color} xmlns="http://www.w3.org/2000/svg">
    <line x1="0.947228" y1="26.4023" x2="26.4031" y2="0.946462" stroke="white"/>
    <line y1="-0.5" x2="36" y2="-0.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 26.7578 26.7559)" stroke="white"/>
  </svg>
)

export const RightArrow = ({color}) => (
  <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.3 8.62362L10.444 4.46562L6.3 0.307617L5.04 0.307617L8.736 4.03162L3.97774e-08 4.03162L0 4.94162H8.694L5.04 8.62362H6.3Z" fill={color}/>
  </svg>
)

export const BackToTop = ({color}) => (
  <svg width="23" height="45" viewBox="0 0 23 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.480469 11.6503L11.5685 0.599609L22.6565 11.6503V15.0103L12.7258 5.15427L12.7258 44.4503H10.2991L10.2991 5.26628L0.480469 15.0103L0.480469 11.6503Z" fill={color}/>
  </svg>
)