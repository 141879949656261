import React, {Component} from 'react';
import Link from '../utils/link';
import {Logo, RightArrow} from '../components/icons'
import { GatsbyImage } from 'gatsby-plugin-image';
import Gif from '../assets/images/footer-icon.gif'
import GifWhite from '../assets/images/footer-icon-white.gif'

const ThemeToggle = ({ onClick, pageContext }) => {

  const [style, setStyle] = React.useState({
    left: '80px',
    top: '80px',
  });

  React.useEffect(() => {

    setStyle({
      left: `${window.innerWidth * 0.9 * Math.random()}px`,
      top: `${window.innerHeight * 0.9 * Math.random()}px`,
    })

  }, [pageContext])

  return (
    <button 
      style={style}
      onClick={onClick} 
      onMouseEnter={() => window?._handleMouseEnter('')}
      onMouseLeave={() => window?._handleMouseLeave()}
      className='theme-toggle' 
    />
  )
}

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
    opacity: false,
    imageActive: null
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { scrolled, opacity } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    const vh = window.innerHeight - 76

    opacity = false

    if (curScroll > vh) opacity = true

    requestAnimationFrame(() => {
      this.setState({ scrolled, opacity })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _toggleOffCanvas = () => {
    window._toggleInfo && window._toggleInfo(true)
    document.body.classList.toggle('off-canvas-active', !this.state.offCanvas)
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  render() {

    const { pageContext, changeTheme } = this.props
    let {offCanvas, scrolled, opacity, imageActive} = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--menu-open'
    if (opacity) headerClass += ' header--opacity'

    const buttonProps = {
      type: 'button',
      onMouseEnter: () => window?._handleMouseEnter('Link'),
      onMouseLeave: () => window?._handleMouseLeave(),
    }

    const { wp } = this.props.data

    return (
      <>
        <header className={headerClass} onMouseEnter={() => this.setState({ opacity: true})}>
          <div className="header__inner">
            <Link 
              to="/" title="Atollon" className='header__logo' 
              {...props}>
              <Logo color={'#FFFFFF'}/>
            </Link>
            <div className='header__nav'>
              <button onClick={this._toggleOffCanvas} className={`header__hamburger ${offCanvas ? 'header__hamburger--active' : ''}`} {...buttonProps}>
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas ? 'off-canvas--active' : ''}`}>
          <div className="off-canvas__inner">
            <button onClick={this._toggleOffCanvas} className='header__hamburger header__hamburger--active' {...buttonProps}>
              <span className="lines"></span>
            </button>
            <nav className="off-canvas__nav">
              <ul>
                <li><Link to="/about/" {...props} onMouseEnter={() => this.setState({ imageActive: 0 })}>About</Link></li>
                <li><Link to="/projects/" {...props} onMouseEnter={() => this.setState({ imageActive: 1 })}>Projects</Link></li>
                <li><Link to="/journal/" {...props} onMouseEnter={() => this.setState({ imageActive: 2 })}>Journal</Link></li>
                <li><Link to="/hivemind/" {...props} onMouseEnter={() => this.setState({ imageActive: 3 })}>Hivemind</Link></li>
                <li><Link to="/contact/" {...props} onMouseEnter={() => this.setState({ imageActive: 4 })}>Contact</Link></li>
              </ul>
            </nav>
          </div>
          <div className='off-canvas__images'>
            { wp.siteSettings.acf.gallery.map((el, i) => {
              const image = el.localFile?.childImageSharp?.gatsbyImageData
              return (
                <div key={i} className={`off-canvas__image${imageActive === i ? ' active' : ''}`}>
                  <GatsbyImage image={image} alt={el.altText} />
                </div>
              )
            })}
          </div>
        </div>
        <ThemeToggle onClick={changeTheme} pageContext={pageContext} />
      </>
    );
  }
}

export default Header;
