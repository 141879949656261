import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment WpTaxSeo on WpTaxonomySEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      schema {
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      title
      slug
      uri
      categories {
        nodes {
          name
          slug
        }
      }
      acf {
        excerpt
        thumbnail {
          portraitImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
          landscapeImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500)
              }
            }
          }
        }
      }
    }
    fragment HivemindCard on WpPost {
      id
      title
      uri
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200)
            }
          }
        }
      }
    }
    fragment ProjectCard on WpProject {
      id
      title
      uri
      disciplines {
        nodes {
          name
          slug
        }
      }
      sectors {
        nodes {
          name
          slug
        }
      }
      acf {
        subtitle
        thumbnail {
          portraitImage {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
          landscapeImage {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(width: 1500)
              }
            }
          }
          video
          videoSize
        }
        webGrid
        noBio
        projectInfo {
          website
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        siteSettings {
          acf {
            announcement {
              link {
                title
                url
              }
            }
            seoText
            spotify
            subscribe {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500)
                  }
                }
              }
              title
            }
            gallery {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default useLayoutQuery