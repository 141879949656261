import React, { useContext } from 'react'
//import { Link as GatsbyLink } from 'gatsby'
//import GatsbyLink from 'gatsby-plugin-transition-link'
import GatsbyLink from 'gatsby-plugin-transition-link/AniLink'
import { ThemeContext } from '../layouts'

const colours = {
  'dark': '#1a1a1a',
  'cactus': '#04C100',
  'light': '#FFFFFF',
} 

const Link = ({ to, children, ...props }) => {

  const theme = useContext(ThemeContext);

  const href = to?.replace(process.env.GATSBY_PREVIEW_URL, '') || ''

  const offsite = ['//', 'mailto:', 'tel:', 'sms:'].findIndex(el => href.indexOf(el) !== -1)

  if (!props.onMouseEnter) {
    props.onMouseEnter = () => window?._handleMouseEnter('Link')
    props.onMouseLeave = () => window?._handleMouseLeave()
  }

  if (offsite === -1) {
    return <GatsbyLink to={href} fade duration={1} {...props}>{ children }</GatsbyLink>
  }

  return (
    <a {...props} href={href} target='_blank' rel='nofollow noopener noreferrer'>
      { children }
    </a>
  )
}

export default Link
