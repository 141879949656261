exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-link-js": () => import("./../../../src/pages/link.js" /* webpackChunkName: "component---src-pages-link-js" */),
  "component---src-templates-discipline-js": () => import("./../../../src/templates/discipline.js" /* webpackChunkName: "component---src-templates-discipline-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-sector-js": () => import("./../../../src/templates/sector.js" /* webpackChunkName: "component---src-templates-sector-js" */)
}

